@media all {
  .c-button {
      position: relative;
      z-index: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      font-weight: 700;
      border-radius: 16px;
      white-space: nowrap;
      border: none;
      outline: 0;
      cursor: pointer;
      overflow: hidden;
      transition: all .25s ease;
      box-shadow: 0 0 2px rgba(0,0,0,.05);
      will-change: transform
  }

  .c-button:active {
      transform: translateY(2px) scale(.98)
  }

  .c-button--primary {
      color: #fff;
      background: linear-gradient(90deg, $primary-color 0, $secondary-color 167%)
  }

  .c-button--primary:hover {
      color: #fff!important
  }

  .c-button--primary:hover:before {
      opacity: 1
  }

  .c-button--primary::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0;
      will-change: transform;
      transition: opacity .35s;
      background: linear-gradient(90deg, $primary-color 0, $secondary-color 107%)
  }

  .c-button--secondary {
      color: $dark;
      background: #fff
  }

  .c-button--secondary:hover {
      color: $dark
  }

  .c-button--small {
      padding: 16px 32px;
      font-size: 16px
  }

  .c-button--large {
      padding: 24px 44px;
      font-size: 18px
  }
}


@media all {
  .c-get {
      padding: 30px 0
  }
}

@media only all and (max-width: 1024px) {
  .c-get {
      padding:40px 0
  }
}

@media only all and (max-width: 768px) {
  .c-get {
      padding:40px 0 80px
  }
}

@media only all and (max-width: 576px) {
  .c-get {
      padding:30px 0 60px
  }
}

@media all {
  .c-get__inner {
      display: flex;
      align-items: center
  }
}

@media only all and (max-width: 768px) {
  .c-get__inner {
      flex-direction:column
  }
}

@media all {
  .c-get__left,.c-get__right {
      width: 50%
  }
}

@media only all and (max-width: 768px) {
  .c-get__left,.c-get__right {
      width:100%
  }
}

@media all {
  .c-get__left {
      max-width: 510px;
      padding-right: 20px;
      margin-right: auto
  }
}

@media only all and (max-width: 768px) {
  .c-get__left {
      max-width:100%;
      padding-right: 0
  }
}

@media all {
  .c-get__right {
      position: relative
  }
}

@media only all and (max-width: 1130px) {
  .c-get__right .c-get__image {
      width:400px;
      height: 420px
  }
}

@media only all and (max-width: 1024px) {
  .c-get__right .c-get__image {
      width:340px;
      height: 360px
  }
}

@media only all and (max-width: 768px) {
  .c-get__right {
      order:-1
  }

  .c-get__right .c-get__image {
      width: 100%;
      height: 380px;
      margin-bottom: 40px
  }

  .c-get__right .c-get__image::before {
      border-radius: 30px
  }

  .c-get__right .c-get__image img {
      border-radius: 30px
  }
}

@media only all and (max-width: 576px) {
  .c-get__right .c-get__image {
      height:280px;
      margin-bottom: 32px
  }

  .c-get__right .c-get__image::before {
      border-radius: 16px
  }

  .c-get__right .c-get__image img {
      border-radius: 16px;
      box-shadow: none
  }
}

@media all {
  .c-get__title {
      margin-bottom: 20px;
      font-size: 50px;
      color: $dark
  }
}

@media only all and (max-width: 1130px) {
  .c-get__title {
      font-size:44px
  }
}

@media only all and (max-width: 1024px) {
  .c-get__title {
      font-size:38px;
      margin-bottom: 24px
  }
}

@media only all and (max-width: 576px) {
  .c-get__title {
      font-size:32px
  }
}

@media all {
  .c-get__description p {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 1.6;
      color: $dark
  }
}

@media only all and (max-width: 1024px) {
  .c-get__description p {
      font-size:16px
  }
}

@media all {
  .c-get__btn {
      display: flex;
      align-items: center;
      margin-top: 40px
  }

  .c-get__btn .cta-button {
      margin-right: 8px
  }

  .c-get__btn .works-button:hover .circle-bg {
      transition: all .2s cubic-bezier(.215, .61, .355, 1);
      transform: scale(.94)
  }

  .c-get__btn .works-button .circle-bg {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 18px;
      height: 18px;
      margin-right: 8px;
      font-size: 14px;
      border-radius: 50%;
      will-change: transform;
      transition: all .2s cubic-bezier(.215, .61, .355, 1)
  }

  .c-get__btn .works-button .button-arrow {
      color: #fff
  }
}

@media only all and (max-width: 1024px) {
  .c-get__btn {
      margin-top:32px
  }
}

@media only all and (max-width: 576px) {
  .c-get__btn .cta-button,.c-get__btn .works-button {
      font-size:15px
  }
}

@media all,bookshop {
  .c-get__image {
      position: relative;
      z-index: 10;
      width: 480px;
      height: 520px;
      margin-left: auto
  }

  .c-get__image::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 60px;
      background: #d5dce3
  }

  .c-get__image img {
      position: absolute;
      top: 0;
      z-index: 1;
      height: 100%;
      width: 100%;
      border-radius: 60px;
      object-fit: cover;
      user-select: none;
      box-shadow: 0 100px 80px rgba(20,149,167,.07),0 41.7776px 33.4221px rgba(20,149,167,.0503198),0 22.3363px 17.869px rgba(20,149,167,.0417275),0 12.5216px 10.0172px rgba(20,149,167,.035),0 6.6501px 5.32008px rgba(20,149,167,.0282725),0 2.76726px 2.21381px rgba(20,149,167,.0196802);
      transition: inherit;
      background: #d5dce3
  }
}

@media all {
  .c-project-card__content::after {
      content: "";
      display: table;
      padding-top: 110%
  }

  .c-project-card__info {
      z-index: 1;
      width: 100%;
      margin-top: auto;
      padding: 0 32px 36px;
      text-align: center;
      pointer-events: none
  }

  .c-project-card__info-wrap {
      overflow: hidden
  }

  .c-project-card__title {
      font-size: 28px;
      line-height: 1.3;
      margin-bottom: 0;
      transform: translate3d(0,100%,0)
  }

  .c-project-card__subtitle {
      font-size: 16px;
      line-height: 1.4;
      opacity: 0;
      transform: translate3d(0,-100%,0)
  }

  .c-project-card__subtitle:hover {
      opacity: .7
  }

  .c-project-card__subtitle,.c-project-card__title {
      color: #fff;
      opacity: 0;
      transition: transform .4s cubic-bezier(.165, .85, .45, 1),opacity .4s cubic-bezier(.165, .85, .45, 1)
  }

  .c-testimonial-card__content {
      width: 100%;
      padding: 32px;
      border-radius: 16px;
      color: #fff;
      background: linear-gradient(90deg, $primary-color 0, $secondary-color 167%)
  }

  .c-testimonial-card__client-meta {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 32px
  }

  .c-testimonial-card__client-text {
      position: relative;
      margin-bottom: 0;
      font-size: 15px;
      line-height: 1.6;
      text-align: center
  }

  .c-testimonial-card__client-text::after {
      content: "“";
      position: absolute;
      top: -10px;
      left: -2%;
      display: block;
      z-index: 2;
      font-size: 100px;
      font-weight: 900;
      line-height: 1;
      color: #fff;
      opacity: .1;
      pointer-events: none
  }

  .c-testimonial-card__image-container {
      position: relative;
      width: 80px;
      height: 80px;
      margin-bottom: 8px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 0 2px rgba(0,0,0,.1)
  }

  .c-testimonial-card__image-container img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover
  }

  .c-testimonial-card__client-info {
      text-align: center
  }

  .c-testimonial-card__client-rate {
      margin-bottom: 8px;
      font-size: 21px;
      line-height: 1;
      letter-spacing: normal
  }

  .c-testimonial-card__client-position {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      opacity: .7
  }

  .testimonial-item .testimonial-content {
      width: 100%;
      padding: 32px;
      border-radius: 16px;
      color: #fff;
      background: linear-gradient(90deg, $primary-color 0, $secondary-color 167%)
  }

  .testimonial-item .testimonial-content .client-meta {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 32px
  }

  .testimonial-item .testimonial-content .client-text {
      position: relative;
      margin-bottom: 0;
      font-size: 15px;
      line-height: 1.6;
      text-align: center
  }

  .testimonial-item .testimonial-content .client-text::after {
      content: "“";
      position: absolute;
      top: -10px;
      left: -2%;
      display: block;
      z-index: 2;
      font-size: 100px;
      font-weight: 900;
      line-height: 1;
      color: #fff;
      opacity: .1;
      pointer-events: none
  }

  .testimonial-item .testimonial-content .image-container {
      position: relative;
      width: 80px;
      height: 80px;
      margin-bottom: 8px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 0 2px rgba(0,0,0,.1)
  }

  .testimonial-item .testimonial-content .image-container img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover
  }

  .testimonial-item .testimonial-content .client-info {
      text-align: center
  }

  .testimonial-item .testimonial-content .client-name {
      margin-bottom: 8px;
      font-size: 21px;
      line-height: 1;
      letter-spacing: normal
  }

  .testimonial-item .testimonial-content .client-position {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      opacity: .7
  }

  .testimonials__slider {
      display: flex
  }

  .tns-horizontal.tns-subpixel>.tns-item {
      display: inline-flex
  }

  .tns-outer {
      position: relative
  }

  .controls {
      display: flex;
      align-items: center;
      outline: 0
  }

  .controls .next,.controls .prev {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin: 0;
      padding: 0;
      font-size: 15px;
      border-radius: 50%;
      box-shadow: 0 0 2px rgba(0,0,0,.07);
      color: $dark;
      background: #fff;
      outline: 0;
      cursor: pointer;
      overflow: hidden;
      transition: all .2s ease
  }

  .controls .next::after,.controls .prev::after {
      content: "";
      position: absolute;
      z-index: -1;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      background: linear-gradient(90deg, $primary-color 0, $secondary-color 167%);
      transition: all .2s ease
  }

  .controls .next:hover,.controls .prev:hover {
      color: #fff;
      background: 0 0
  }

  .controls .next:hover::after,.controls .prev:hover::after {
      opacity: 1;
      visibility: visible
  }

  .controls .next:active,.controls .prev:active {
      transform: scale(.97) translateY(1px)
  }

  .controls .prev {
      margin-right: 4px
  }
}

@media all {
    .c-feature-card {
        margin-bottom: 20px;
        will-change: transform;
        transition: transform .2s
    }

    .c-feature-card:hover {
        transform: translateY(-4px)
    }

    .c-feature-card:hover .article__inner {
        box-shadow: 0 20px 40px rgba(0,0,0,.1)
    }
}

@media only all and (max-width: 768px) {
    .c-feature-card:last-child {
        margin-bottom:0
    }
}

@media all {
    .c-feature-card__inner {
        border-radius: 16px;
        overflow: hidden;
        transform: translate(0);
        box-shadow: 0 0 2px rgba(0,0,0,.07);
        transition: box-shadow .2s
    }

    .c-feature-card__content {
        position: relative;
        z-index: 1;
        padding: 28px;
        margin-top: -20px;
        border-radius: 16px 16px 0 0;
        background: #fff
    }

    .c-feature-card__title {
        margin: 16px 0;
        font-size: 24px;
        line-height: 1.3;
        font-weight: 700
    }
}

@media only all and (max-width: 576px) {
    .c-feature-card__title {
        font-size:22px
    }
}

@media all {
    .c-feature-card__description {
        font-size: 15px;
        line-height: 1.6;
        margin-bottom: 20px;
        color: #110e38;
        max-height: 10em;
        overflow: scroll;
    }
}