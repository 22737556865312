// *****************
// Global Variables
// *****************

// Spaces
$base-spacing-unit: 10px;

// Border radius
$global-radius: 16px;

// Transition
$global-transition: all .35s;


// *****************
// Typography
// *****************
$base-font-size: 18px;
$base-font-style: normal;
$base-font-variant: normal;
$base-font-weight: normal;
$base-font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
$base-line-height: 1.8;

// Headings
$heading-font-weight: 900;
$heading-font-family: Helvetica Neue, Helvetica, Arial, sans-serif;

$font-size-h1:  36px;
$font-size-h2:  28px;
$font-size-h3:  24px;
$font-size-h4:  20px;
$font-size-h5:  18px;
$font-size-h6:  16px;


$heading-line-height: 1.2;
$heading-letter-spacing: -1px;