@media all {
  .related-posts {
   display:none;
   margin-top:80px
  }
  .related-posts.is-related {
   display:block
  }
  .related-posts .related-tag {
   text-transform:capitalize
  }
 }
 @media only all and (max-width: 576px) {
  .related-posts {
   margin-top:60px
  }
  .related-posts .row .col:last-child {
   margin-bottom:0
  }
 }
 @media all {
  .post__comments {
   max-width:780px;
   margin:80px auto 40px
  }
 }
 @media only all and (max-width: 576px) {
  .post__comments {
   margin:60px auto 0
  }
 }
 @media all {
  .project-subtitle {
   margin:20px 0 0;
   font-size:21px;
   line-height:1;
   font-weight:700
  }
 }
 @media only all and (max-width: 576px) {
  .project-subtitle {
   margin:16px 0 0;
   font-size:18px
  }
 }
 @media all {
  .project__navigation {
   max-width:780px;
   margin:80px auto 40px;
   text-align:center
  }
 }
 @media only all and (max-width: 768px) {
  .project__navigation {
   margin:60px auto 40px
  }
 }
 @media all {
  .project__prev:hover {
   color:#110e38
  }
  .project__prev:hover .project__nav__title .arrow-right {
   opacity:1;
   transform:translate(3px)
  }
  .project__nav__info {
   display:inline-block;
   margin:0 20px 4px 0;
   font-size:12px;
   font-weight:700;
   line-height:1.2;
   text-transform:uppercase
  }
  .project__nav__title {
   margin-bottom:20px;
   font-size:40px;
   line-height:1.2
  }
  .project__nav__title .arrow-right {
   margin-left:2px;
   font-size:30px;
   opacity:0;
   transition:.2s linear
  }
 }
 @media only all and (max-width: 768px) {
  .project__nav__title {
   font-size:36px
  }
 }
 @media all{
  .c-blog-card {
   margin-bottom:20px;
   will-change:transform;
   transition:transform .2s
  }
  .c-blog-card:hover {
   transform:translateY(-4px)
  }
  .c-blog-card:hover .article__inner {
   box-shadow:0 20px 40px #0000001a
  }
 }
 @media only all and (max-width: 768px) {
  .c-blog-card:last-child {
   margin-bottom:0
  }
 }
 @media all {
  .c-blog-card__inner {
   border-radius:16px;
   overflow:hidden;
   transform:translate(0);
   box-shadow:0 0 2px #00000012;
   transition:box-shadow .2s
  }
  .c-blog-card__image-wrap {
   background:#fff
  }
  .c-blog-card__image {
   position:relative;
   display:block;
   height:0;
   padding-bottom:71.25%;
   background:#d5dce3;
   overflow:hidden
  }
  .c-blog-card__image img {
   position:absolute;
   width:100%;
   height:100%;
   object-fit:cover;
   background-color:#d5dce3
  }
  .c-blog-card__content {
   position:relative;
   z-index:1;
   padding:28px;
   margin-top:-20px;
   border-radius:16px 16px 0 0;
   background:#fff
  }
  .c-blog-card__title {
   margin:16px 0;
   font-size:24px;
   line-height:1.3;
   font-weight:700
  }
  .c-blog-card__title a:hover {
   color:#110e38
  }
 }
 @media only all and (max-width: 576px) {
  .c-blog-card__title {
   font-size:22px
  }
 }
 @media all {
  .c-blog-card__excerpt {
   display:-webkit-box;
   font-size:15px;
   line-height:1.6;
   margin-bottom:20px;
   color:#110e38;
   overflow-y:hidden;
   -webkit-line-clamp:3;
   -webkit-box-orient:vertical
  }
  .c-blog-card__meta {
   display:flex;
   align-items:center;
   flex-wrap:wrap
  }
  .c-blog-card__author-image {
   position:relative;
   width:44px;
   height:44px;
   margin-right:8px;
   border-radius:50%;
   overflow:hidden;
   background-color:#d5dce3
  }
  .c-blog-card__author-image img {
   position:absolute;
   top:0;
   left:0;
   height:100%;
   width:100%;
   border-radius:50%;
   object-fit:cover
  }
  .c-blog-card__info {
   display:flex;
   flex-direction:column
  }
  .c-blog-card__author-name {
   margin-bottom:6px;
   font-size:16px;
   line-height:1;
   font-weight:600
  }
  .c-blog-card__date {
   font-size:12px;
   line-height:14px
  }
}