@media all {
  .c-page-heading {
      max-width: 780px;
      margin: 0 auto;
      padding: 60px 0 60px;
      text-align: center
  }
}

@media only all and (max-width: 1024px) {
  .c-page-heading {
      padding:50px 0 60px
  }
}

@media only all and (max-width: 768px) {
  .c-page-heading {
      padding:40px 0
  }
}

@media only all and (max-width: 576px) {
  .c-page-heading {
      padding:30px 0
  }
}

@media all {
  .c-page-heading__title {
      margin: 0;
      font-size: 58px;
      line-height: 1.12
  }
  .c-page-heading__description {
    margin:30px auto 0;
    max-width:560px;
    line-height:1.6
   }
}

@media only all and (max-width: 768px) {
  .c-page-heading__title {
      font-size:36px
  }
}

@media only all and (max-width: 576px) {
  .c-page-heading__title {
      font-size:32px
  }
  .c-page-heading__description {
    margin:24px auto 0
   }
}

@media all {
  .c-page-image {
      position: relative;
      transform: translate(0);
      padding-top: 56.25%;
      margin-bottom: 48px;
      min-height: 280px;
      border-radius: 16px;
      overflow: hidden;
      background: #d5dce3
  }

  .c-page-image img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none
  }
}

@media only all and (max-width: 1024px) {
  .c-page-image {
      padding-top:65%;
      margin-bottom: 40px
  }
}

@media only all and (max-width: 576px) {
  .c-page-image {
      margin-bottom:32px
  }
}