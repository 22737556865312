/* Social Links */
.social {
  text-align: center;

  .social__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .social__item {
    display: inline-block;
    margin: 0 2px;
  }

  .social__link {
    position: relative;
    z-index: 1;
    padding: 0 10px;
    font-size: 21px;
    line-height: 1;

    &:hover {
      color: $white;

      &::after {
        transform: translate3d(-50%,-50%,0) scale(0.9);
      }
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%,-50%,0) scale(0);
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      border-radius: 50%;
      background: linear-gradient(90deg, $primary-color 0%, $secondary-color 167%);
      transition: all .25s;
    }
  }
}